export const RESTRICTED_APP_KEYWORD = 'app';
export const BASE_URL = `/${RESTRICTED_APP_KEYWORD}`;

export const PUBLIC_ROUTE_URLS = {
  ENTRY_EXIT: `${BASE_URL}/entry-exit`,
  COURSE_COMPLETION: `${BASE_URL}/course-completion`
};

export const ROUTE_URLS = {
  LOGIN: `${BASE_URL}/login`,
  LANDING_PAGE: `${BASE_URL}/landing-page`,
  MY_PROFILE: `${BASE_URL}/my-profile`,
  USERS: `${BASE_URL}/users`,
  CLIENTS: `${BASE_URL}/organizations`,
  COMMUNICATION: `${BASE_URL}/communication`,
  COMMUNICATIONS: `${BASE_URL}/communications`,
  REPORTS: `${BASE_URL}/reports`,
  INTEL: `${BASE_URL}/intel`,
  ROLES: `${BASE_URL}/role-management`,
  MY_ORGANIZATION: `${BASE_URL}/my-organization`,
  PREFERENCES: `${BASE_URL}/preferences`,
  SUPPORT: `${BASE_URL}/support`,
  MAP: `${BASE_URL}/map`,
  MEDICAL_PROVIDER_SEARCH: `${BASE_URL}/medical-provider-search`,
  AXA_MY_TRIP: `${BASE_URL}/axa-my-trip`,
  PERSON: `${BASE_URL}/person`,
  BULK_UPLOAD: `${BASE_URL}/bulk-upload`,
  LINKS: `${BASE_URL}/links`,
  SITES: `${BASE_URL}/sites`,
  MEDICAL_NETWORK: `${BASE_URL}/medical-network`,
  PNR_SEARCH: `${BASE_URL}/pnr-search`,
  SURVEY: `${BASE_URL}/survey`,
  E_LEARNING: `${BASE_URL}/e-learning`,
  CRITICAL_OUTREACH_ALERTS: `${BASE_URL}/goc-alerts`,
  CRITICAL_OUTREACH_CLIENTS: `${BASE_URL}/critical-outreach-clients`,
  PROVIDER_LOGIN: `${BASE_URL}/provider-login`,
  IN_APP_MESSAGE_NOTIFICATIONS: `${BASE_URL}/in-app-messaging`,
  IMG_MEDICAL_NETWORK: `${BASE_URL}/img-medical-network`,
  EMAIL_READ_RECEIPT: `${BASE_URL}/email-read-receipt`,
};

export const INTEL_SUB_PATHS = {
  ALERTS: `alerts`,
  ANALYSIS: `analysis`,
  LOCATION: `locations`,
  ADVICE: `advice`,
  ORIGINAL: 'original',
  EDIT_RATING: 'edit-rating',
  WORLD_AIRLINE_MONITOR: 'world-airline-monitor',
  INCIDENTS: 'incidents',
  LARGE_SCALE_EVENT: 'large-scale-event'
};

export const INTEL_LOCATION_DETAILS_PATHS = {
  OVERVIEW: 'overview',
  SECURITY: 'security',
  ENVIRONMENT: 'environment',
  INFRASTRUCTURE: 'infrastructure',
  HEALTHMEDICAL: 'healthmedical',
  POLITICAL: 'political',
  LEGAL: 'legal',
  ENTRYEXIT: 'entryexit',
  CULTURE: 'culture',
  FINANCIAL: 'financial',
  LGBTQSECURITY: 'lgbtqsecurity'
};

export const GOC_FEED_PATHS = {
  CRITICAl_OUTREACH_STATUS: 'critical-outreach-status',
  RESPONSE_SUMMARY: 'response-summary',
  POC_CONTACT_ISSUES: 'poc-contact-issues',
  EXPOSURE_CHECK_STATUS: 'exposure-check-status'
}

export const ORG_SUB_PATHS = {
  USERS: 'users',
  SUBSCRIPTIONS: 'subscriptions',
  ROLES: 'roles',
  USER_REGISTRATION: 'userRegistration',
  CLIENT_CONFIGURATION: 'clientConfiguration',
  RESOURCES: 'resources',
  DATA_MANAGEMENT: 'dataManagement'
};

export const BULK_UPLOAD_SUB_PATHS = {
  PEOPLE: 'people',
  TRIPS: 'trips',
  REFERENCE_CODES: 'reference-codes',
  EXPATRIATIONS: 'expatriations',
  SITES: 'sites',
  TRAVEL: 'travel-manager-trip'
};

export const PERSON_SUB_PATHS = {
  PERSON_SEARCH: 'person-search',
  PERSON_CREATE: 'create',
  PERSON_CREATE_TRIP: 'create-trip',
  PERSON_EDIT: 'edit',
  GROUPS: 'groups',
  MERGE_PROFILES: 'merge-profiles',
  POST_BOOKING_APPROVAL_MANAGEMENT: 'post-booking-approval-mgmt',
  TRAVEL_SEARCH: 'travel-search',
  EXPATRIATIONS: 'expatriations'
};

export const PERSON_TAB_PATHS = {
  PROFILE_DETAILS: 'profileDetails',
  ACCOUNT_DETAILS: 'accountDetails',
  PREFERENCES: 'preferences',
  COMMUNICATIONS: 'communications',
  MOBILE_LOCATIONS: 'mobileLocations',
  TRIPS: 'trips',
  EXPATRIATIONS: 'expatriations',
  SITES: 'sites'
};

export const PERSON_EDIT_SUB_PATHS = {
  NAME: 'name',
  ORGANIZATION: 'organization',
  EMAIL: 'email',
  PHONE: 'phone',
  ADDRESS: 'address'
};

export const USER_TAB_PATHS = {
  PROFILE_DETAILS: 'profileDetails',
  PERMISSIONS: 'permissions',
  CONTACT_INFORMATION: 'contactInformation',
  PREFERENCES: 'preferences',
  COMMUNICATIONS: 'communications'
};

export const COMM_SUB_PATHS = {
  ACCOUNT: 'account',
  MESSAGE_DETAILS: 'messageDetails',
  USER_MESSAGE_DETAILS: 'userMessageDetails',
  MASS_NOTIFICATION: 'mass-notif',
  MESSAGE_TEMPLATES: 'message-templates',
  CREATE: 'create',
  EDIT: 'edit',
  DUPLICATE: 'duplicate',
  SUMMARY: 'summary',
  AUTO_CHECK_INS: 'auto-check-ins',
  IN_APP_MESSAGE_NOTIFICATIONS: 'in-app-messaging',
  IN_APP_MESSAGING: 'in-app-messaging',
  NOTIFICATIONS: 'notifications',
};

export const USERS_TAB_PATHS = {
  API_USERS: 'apiUsers',
  APP_USERS: 'appUsers'
};

export const ORG_TAB_PATHS = {
  PROFILE_DETAILS: 'profileDetails',
  SUBSCRIPTIONS: 'subscriptions',
  USERS: 'users',
  ROLES: 'roles',
  USER_REGISTRATION: 'userRegistration',
  CLIENT_CONFIGURATION: 'clientConfiguration',
  RESOURCES: 'resources',
  DATA_MANAGEMENT: 'dataManagement',
  GOC_TOOLS: 'goc-tools'
};

export const USER_REGISTRATION_PATHS = {
  EMAIL_DOMAIN: 'emailDomain',
  MEMBER_ID: 'memberId',
  FSSO: 'FSSO',
  MFA: 'mfa'
};

export const CLIENT_CONFIGURATION_PATHS = {
  URL_HOTLINE: 'url-hotline',
  ANALYST: 'analyst',
  LOGO: 'logo',
  LINKS: 'links',
  ONBOARDING: 'onboarding',
  CUSTOM_INTELLIGENCE: 'custom-intelligence',
  CUSTOM_LOGIN_SCREEN: 'custom-login-screen',
  CUSTOM_CONTACTS: 'custom-contacts',
  EMAILS: 'emails',
  THREAT_ZONES: 'threat-zones',
  CUSTOM_FIELDS: 'custom-fields',
  TRIP_CUSTOM_FIELDS: 'trip-custom-fields',
  MOBILE: 'mobile',
  PEOPLE_NOTIFICATIONS: 'people-notifications',
  MASS_NOTIFICATION: 'mass-notification',
  AUTOMATED_CHECKINS: 'automated-checkins',
  ELEARNING: 'e-learning',
  IN_APP_NOTIFICATION: 'in-app-notification',
  POST_BOOKING_APPROVAL: 'post-booking-approval',
  ORGANIZATION_RECIPIENTS: 'organization-recipients',
  LOCKED_FIELDS: 'locked-fields'
};

export const CUSTOM_INTELLIGENCE_SUB_PATHS = {
  INTELLIGENCE_RESTRICTIONS: 'intelligence-restrictions',
}

export const CLIENT_CONFIGURATION_SUB_PATHS = {
  EDIT: 'edit'
}

export const DATA_MANAGEMENT_PATHS = {
  DATA_RETENTION: 'data-retention',
  TRAVEL_DATA_INGEST: 'travel-data-ingest',
  PERSON_DATA_FEEDS: 'person-data-feeds',
  ADVANCED_PERSON_MATCHING: 'advanced-person-matching',
  SITE_DATA_FEEDS: 'site-data-feeds'
};

export const ADVANCED_PERSON_MATCHING_SUB_PATHS = {
  EDIT: 'edit'
}

export const DATA_MANAGEMENT_DATA_FEEDS_PATHS = {
  DETAILS: 'details'
};

export const CLIENT_CONFIG_EMAILS_SUB_PATHS = {
  ACCOUNT_ACTIVATION: 'account-activation',
  PASSWORD_RESET: 'password-reset',
  ALERT_NOTIFICATION: 'alert-notification',
  INCIDENT_NOTIFICATION: 'incident-notification',
  ALERT_PREFERENCE_EXPIRY: 'alert-preference-expiry',
  DAILY_INTELLIGENCE_BRIEFING_DIB: 'daily-intel-brief-dib',
  DAILY_INTELLIGENCE_BRIEFING_NO_CONTENT: 'daily-intel-brief-no-content',
  SECURITY_OUTLOOK: 'security-outlook',
  HEALTH_OUTLOOK: 'health-outlook',
  LARGE_SCALE_EVENT: 'large-scale-event',
  RISK_RATINGS_CHANGES: 'risk-ratings-changes',
  CRISIS_SIGNAL_NOTIFICATIONS: 'crisis-signal-notifications',
  SAFETY_CHECK_IN_NOTIFICATIONS: 'safety-check-in-notifications',
  CRISIS_SIGNAL_CONFIRMATION_NOTIFICATIONS: 'crisis-signal-confirmation-notifications',
  SAFETY_CHECK_IN_CONFIRMATION_NOTIFICATIONS: 'safety-check-in-confirmation-notifications',
  TRIP_BOOKING_NOTIFICATION: 'trip-booking-notifications',
  MULTIPLE_PEOPLE_ON_FLIGHT_NOTIFICATION: 'multiple-people-on-flight-notifications',
  NON_PREFERRED_AIRLINE_NOTIFICATION: 'non-preferred-airline-notification',
  PRE_TRAVEL_NOTIFICATIONS: 'pre-travel-notification',
  TRIPS_AFFECTED_BY_ALERTS: 'trips-affected-by-alerts-notification',
  PRE_EXPAT_NOTIFICATION: 'pre-expat-notification',
  EXPATRIATIONS: 'expatriations',
  EXPAT_ALERT_NOTIFICATION: 'expat-alert-notification',
  EMAIL_SENT_FROM: 'email-sent-from',
  ELEARN_COURSE_COMPLETION_NOTIFICATION: 'elearn-course-completion-notification',
  MOBILE_IN_APP_MESSAGE_NOTIFICATION: 'in-app-message-notifications',
  MASS_NOTIFICATION: 'mass-notif',
  SITE_POC_ALERT_NOTIFICATION: 'site-poc-alert-notification',
  SITE_POC_INCIDENT_NOTIFICATION: 'site-poc-incident-notification'
};

export const CLIENT_CONFIG_TRAVEL_DATA_SUB_PATHS = {
  EMAIL_PARSING: 'email-parsing',
};

export const ORG_ROLE_TAB_PATHS = {
  ROLE_DETAILS: 'roleDetails',
  USERS: 'users'
};

export const ORG_DATA_FEED_TAB_PATHS = {
  DETAILS: 'data-feed-details',
  FILES: 'files-on-server',
  RESULTS: 'results-table',
  TEST_RESULTS: 'test-results-table'
};

export const PREFERENCES_SUB_PATHS = {
  NOTIFICATIONS: 'notifications'
};

export const NOTIFICATIONS_PREFS_PATHS = {
  LANGUAGE: 'language',
  ALERTS: 'alerts',
  DAILY_INTEL_BRIEFING: 'dib',
  ANALYSIS: 'analysis',
  RATINGS_CHANGES: 'ratingsChanges',
  SESSION_TIMEOUT: 'sessionTimeout',
  ALERT_PREFERENCE: 'alert-preference',
  TRIP_BOOKINGS: 'tripBookings',
  MULTIPLE_PEOPLE_ON_FLIGHT: 'multiplePeopleOnFlight',
  NON_PREFERRED_AIRLINES: 'nonPreferredAirlines',
  INCIDENT_PREFERENCE: 'incident-preference',
  EXPATRIATIONS: 'expatriation-preference',
  IN_APP_MESSAGES: 'in-app-messages'
};

export const LOCATION_SUB_PATHS = {
  REPORT: 'report',
  BRIEFS: 'briefs',
  RATINGS: 'ratings'
};

export const CUSTOM_LOGIN_PATHS = {
  EMPTY: '',
  LOGIN: 'login',
  CONTACT: 'contact'
};

export const SITE_SUB_PATHS = {
  CREATE: 'create',
  EDIT: 'edit'
}

export const SITE_TAB_PATHS = {
  PROFILE_DETAILS: 'profile',
  POINTS_OF_CONTACT: 'points-of-contact',
  OCCUPANTS: 'occupants',
  RESOURCES: 'resources',
  ALERT_HISTORY: 'alert'
};

export const PERSON_SITES_SUB_PATHS = {
  POINT_OF_CONTACT: 'poc',
  OCCUPANT: 'occupant'
}

export const CRITICAL_OUTREACH_CLIENTS_PATH = {
  PROFILE: 'profile',
  PROFILE_DETAILS: 'profileDetails',
  ORGANIZATION_DETAILS: 'organizationDetails',
  NOTIFICATION_CONFIGURATION: 'notificationConfigurations',
  HOTLINE_NUMBER: 'hotlineNumber',
  CRITICAL_OUTREACH: 'critical-outreach'
}

export const CRITICAL_OUTREACH_CLIENTS_TABS = {
  ORGANIZATION_DETAILS: 'organization-details',
  NOTIFICATIONS_PREFERENCE: 'notification-configurations',
  HOTLINE_NUMBER: 'hotline-number'
}

export const AUTO_CHECK_IN_PATH = {
  MESSAGE: 'message',
  RECIPIENTS: 'recipients',
  SUMMARY: 'summary'
};
